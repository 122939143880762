* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Asap", sans-serif;
  background-color: #fafaf9;
  color: #1c1917;
}

#root {
  max-width: 1024px;
  margin: 0 auto;
}

h1 {
  font-size: 4em;
  letter-spacing: 0.1em;
}

h4 {
  font-size: 1.5em;
  font-weight: normal;
  font-style: italic;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
  color: inherit;
}

button {
  background-color: transparent;
  border: 1px solid black;
  border-radius: 4px;
  cursor: pointer;
}

#Intro {
  display: flex;
  column-gap: 80px;
  margin-top: 80px;
  margin-bottom: 80px;
}

.profile-picture {
  height: 200px;
  border-radius: 10px;
}

#intro--subheading {
  margin-top: 8px;
}

.intro--nav {
  margin-top: 24px;
}

.page-links {
  display: flex;
  column-gap: 18px;
}

.page-link {
  font-size: 1.25em;
  color: #44403c;
}

.link-underline {
  display: none;
  background-color: #34d399;
  border-radius: 6px;
  height: 6px;
  position: relative;
}

.large-text {
  font-size: 1.75em;
  text-indent: 80px;
}

#About {
  margin-bottom: 160px;
}

#Projects {
  margin-bottom: 160px;
}

#Contact {
  margin: 0 auto;
  margin-bottom: 160px;
  width: 80%;
}

.contact--form {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  margin-top: 40px;
}

.form-row {
  display: flex;
  flex-direction: row;
  column-gap: 20px;
}

.form-field {
  flex-basis: 0;
  flex-grow: 1;
}

.form-label {
  display: block;
  font-size: 1.125em;
  color: #57534e;
  margin-bottom: 8px;
}

.form-required {
  color: #dc2626;
  margin-left: 4px;
}

.form-input {
  width: 100%;
  padding: 12px;
  border: 1px solid #a8a29e;
  border-radius: 6px;
  background-color: transparent;
}

.form-textarea {
  resize: vertical;
}

.form-button {
  padding: 10px;
  border: none;
  border-radius: 12px;
  font-size: 1.125em;
  color: #f5f5f4;
  background-color: #34d399;
}
